import { FC, useEffect, useState } from 'react'
import { currencyFormat } from '../../../helpers'
import { Button, Pagination, Select } from 'antd'
import style from './style.m.less'
import request from '../../../../services/request'

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

type PaginationType = {
    page?: number
    pageSize?: number
}

interface IRepaymentsBlock {
    eventDetails: any
    getCustomData: (data: PaginationType) => void
}

const RepaymentsBlock: FC<IRepaymentsBlock> = ({ eventDetails, getCustomData }) => {
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const list = eventDetails?.payload?.repayments
    const total = eventDetails?.payload?.total
    const [selectValue, setSelectValue] = useState<string>('---')
    const [disabledButton, setDisabledButton] = useState<boolean>(true)
    const getData = () => {
        const data = []
        for (const repayment of eventDetails.payload.repayments) {
            data.push({ value: repayment.user_id, label: repayment.user_name })
        }
        return data
    }
    const selectChange = (value: string) => {
        setSelectValue(value)
    }
    useEffect(() => {
        if (selectValue !== '---') {
            setDisabledButton(false)
        }
    }, [selectValue])

    async function downloadPdf() {
        const repayment = eventDetails.payload.repayments.find(
            (repayment: any) => repayment.user_id === selectValue,
        )
        const { data: result } = await request.get(
            '/api/report1/v1/green_tools/all_green_repayments/',
        )
        const value = result.detail.find((v: any) => v.user_id === selectValue)
        const { user_id, user_name, volume } = repayment
        const { account_number, certificate_id, generator_id, owner_generator_id } =
            eventDetails.payload
        const { data: pdfFile, headers: pdfHeaders } = await request.get(
            '/api/report1/v1/files/pdf_file_new',
            {
                params: {
                    user_id: user_id,
                    template_type: 'new',
                    account_number: account_number,
                    volume: volume,
                    generator_id: generator_id,
                    owner_generator_id: owner_generator_id,
                    certificate_id: certificate_id,
                    client_name: user_name,
                    idx: value.idx,
                },
                responseType: 'blob',
            },
        )
        const file_ref = document.createElement('a')
        file_ref.href = URL.createObjectURL(new Blob([pdfFile], { type: 'application/pdf' }))
        file_ref.download = pdfHeaders['filename']
        file_ref.target = '_blank'
        file_ref.click()
        URL.revokeObjectURL(file_ref.href)
        file_ref.remove()
    }

    return (
        <>
            <div className={style.energyBlock}>
                <div className={style.energyHeader}>{`Потребители СП (${total})`}</div>
                <div className={style.energyWrapper}>
                    <div className={style.energyTop}>Потребитель</div>
                    <div className={style.energyTop}>Объем, кВт*ч</div>
                </div>
                {list?.map((item: any, index: number) => {
                    return (
                        <div
                            className={
                                index === list.length - 1
                                    ? style.energyItemWrapperBottom
                                    : style.energyItemWrapper
                            }
                        >
                            <div className={style.energyItem}>{item.user_name}</div>
                            <div className={style.energyItem}>{currencyFormat(item.volume)}</div>
                        </div>
                    )
                })}
            </div>
            {/*
            <div>
                <Select onChange={selectChange} options={getData()} />
            </div>
            <div>
                <Button disabled={disabledButton} onClick={downloadPdf}>
                    Выгрузить свидетельство о погашении СП
                </Button>
            </div>
            */}
            {total > 10 && (
                <Pagination
                    className={style.pagination}
                    pageSize={pagination.pageSize}
                    current={pagination.page}
                    total={total || 0}
                    onChange={(page, pageSize) => {
                        getCustomData({ page, pageSize })
                        setPagination({ page, pageSize })
                    }}
                    showSizeChanger
                />
            )}
        </>
    )
}

export default RepaymentsBlock
